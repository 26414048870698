import React from "react";
import ReactMarkdown from "react-markdown";
import { captionPlugin } from "~utils/markdown";

import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";

type Props = {
  body: string;
};

const Component: React.VFC<Props> = (props) => {
  const { body } = props;

  return (
    <div className={styles.ArticleBody}>
      <ReactMarkdown rehypePlugins={[captionPlugin]}>{body}</ReactMarkdown>
    </div>
  );
};

export const ArticleBody: React.VFC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query ArticleBodyComponent {
      quoteIcon: file(relativePath: { eq: "quoteIcon.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <Component
      {...{ ...props, ...{ quoteIcon: data.quoteIcon?.publicURL || "" } }}
    />
  );
};
