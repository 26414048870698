import React, { useMemo } from "react";
import { graphql } from "gatsby";

import { Article } from "~components/articles/article";
import { formatDate } from "~utils";

type Props = {
  data: GatsbyArticlePageQuery;
};

const Container: React.FC<Props> = (props) => {
  const { data } = props;
  const articleData = useMemo(() => {
    if (data.contentfulArticle === undefined || data.contentfulArticle === null)
      return;

    return {
      slug: data.contentfulArticle.slug ?? "",
      title: data.contentfulArticle.title ?? "",
      description: data.contentfulArticle.description ?? "",
      thumbnail: {
        url: data.contentfulArticle.thumbnail?.file?.url
          ? `https:${data.contentfulArticle.thumbnail.file.url}`
          : undefined,
      },
      updatedAt: data.contentfulArticle.updatedAt
        ? formatDate(data.contentfulArticle.updatedAt)
        : undefined,
      body: data.contentfulArticle.body?.body ?? undefined,
      toc: data.contentfulArticle.body?.childMarkdownRemark?.headings?.map(
        (value) => ({
          id:
            `/articles/${data.contentfulArticle?.slug}/#${value?.id}` ??
            undefined,
          value: value?.value ?? undefined,
        }),
      ),
      category: data.contentfulArticle.category
        ? {
            name: data.contentfulArticle.category.name ?? "",
            link: data.contentfulArticle.category.slug
              ? `/assessment/${data.contentfulArticle.category.slug}`
              : "",
            articles: data.contentfulArticle.category.article
              ?.filter(
                (article) => article?.slug !== data.contentfulArticle?.slug,
              )
              .map((article) => ({
                title: article?.title ?? "",
                link: article?.slug ? `/articles/${article.slug}` : "",
              })),
          }
        : undefined,
    };
  }, [data]);

  if (articleData === undefined) return null;

  return <Article article={articleData} />;
};

export default Container;

export const query = graphql`
  query ArticlePage($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      id
      updatedAt
      slug
      title
      thumbnail {
        file {
          url
        }
      }
      description
      body {
        childMarkdownRemark {
          headings(depth: h2) {
            id
            value
          }
        }
        body
      }
      category {
        article {
          slug
          title
        }
        name
        slug
      }
    }
  }
`;
