import React, { useState, useEffect, useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ArticleBody } from "../article-body";
import { BreadCrumbs, BreadCrumbsItem } from "~components/utils/bread-crumbs";
import { Chip } from "../chip";
import { ChipRowList } from "../chip-row-list";
import * as styles from "./index.module.scss";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { VoicesLinkFooter } from "~components/assessment/voice-link-footer";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";

export type ArticleData = {
  slug: string;
  title: string;
  description: string;
  updatedAt?: string;
  body?: string;
  thumbnail: {
    url?: string;
  };
  toc?: {
    id?: string;
    value?: string;
  }[];
  category?: {
    name: string;
    link: string;
    articles?: {
      title: string;
      link: string;
    }[];
  };
};

type ContainerProps = {
  article: ArticleData;
};

type Props = {
  data: GatsbyArticlePageComponentQuery;
  breadCrumbsItems?: BreadCrumbsItem[];
} & ContainerProps;

const SPComponent: React.VFC<Props> = (props) => {
  const { article, breadCrumbsItems, data } = props;
  useEffect(() => {
    const hatenaHelper = document.createElement("script");
    hatenaHelper.src = "https://b.st-hatena.com/js/bookmark_button.js";
    document.body.appendChild(hatenaHelper);

    const twitterHelper = document.createElement("script");
    twitterHelper.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(twitterHelper);

    if (document.getElementById("script-facebook") === null) {
      const facebookHelper = document.createElement("script");
      facebookHelper.src =
        "https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v10.0";
      facebookHelper.id = "script-facebook";
      document.body.appendChild(facebookHelper);
    } else {
      (window as any).FB.XFBML.parse();
    }
  }, []);
  const CONTACT_FORM_ID =
    "2VrF8TwHsoWuBNLF2ujgtsJl4jhaTmjJehw1FjEOUIQ8wgwbKBfFUlETYL07oEZ6H";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: article.title,
          description: article.description,
        }}
      >
        <div className={styles.Article}>
          {breadCrumbsItems && (
            <div className={styles.Article_BreadCrumbs}>
              <BreadCrumbs items={breadCrumbsItems} />
            </div>
          )}

          <div className={styles.Article_Wrapper}>
            <div className={styles.Article_Date}>
              <span>{article.updatedAt} 更新</span>
            </div>
            <div className={styles.Article_Category}>
              {article.category?.name && (
                <ChipRowList>
                  <Chip
                    text={article.category?.name}
                    link={article.category.link}
                  />
                </ChipRowList>
              )}
            </div>
            <h1 className={styles.Article_Title}>{article.title}</h1>
            <div className={styles.Article_Summary}>
              {data.summaryIcon?.publicURL && (
                <img
                  src={data.summaryIcon.publicURL}
                  alt=""
                  className="inline pr-2"
                />
              )}
              <span className="font-bold text-s">この記事について</span>
              <p className="pt-2">{article.description}</p>
            </div>
          </div>

          {/*  <div className={styles.Article_Wrapper}>
          {article.toc != null && (
            <div className={styles.Article_Toc}>
              <h4 className="article__heading--s">目次</h4>
              <TOC toc={article.toc} />
            </div>
          )}
        </div> */}
        </div>

        <div className={styles.Article_Wrapper}>
          {article.body && <ArticleBody body={article.body} />}
          {/* <div className={styles.Article_Others}>
                {article.category?.articles && (
                  <>
                    <h4>{`${article.category.name}の解説記事`}</h4>
                    <div className="items-stretch flex">
                      <RowLinkCardList
                        articles={article.category.articles.map((v) => ({
                          name: v.title,
                          link: v.link,
                        }))}
                      />
                    </div>
                  </>
                )}
              </div> */}
        </div>

        <div className={styles.Article_ShareButtonWrapper}>
          <li className={styles.Article_ShareButton}>
            <div className={styles.Article_ShareButton__Twitter}>
              <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="twitter-share-button"
              >
                Tweet
              </a>
            </div>
          </li>
          <li className={styles.Article_ShareButton}>
            <a
              href="https://b.hatena.ne.jp/entry/"
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="basic-label-counter"
              data-hatena-bookmark-lang="ja"
              title="このエントリーをはてなブックマークに追加"
              data-show-count="false"
            >
              <img
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
                width="20"
                height="20"
              />
            </a>
          </li>
        </div>

        <VoicesLinkFooter />
      </Assessment2PaneLayout>
    </>
  );
};

const PCComponent: React.VFC<Props> = (props) => {
  const { article, breadCrumbsItems, data } = props;
  const CONTACT_FORM_ID =
    "2VrF8TwHsoWuBNLF2ujgtsJl4jhaTmjJehw1FjEOUIQ8wgwbKBfFUlETYL07oEZ6H";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: article.title,
          description: article.description,
        }}
      >
        <div className={styles.Article}>
          {breadCrumbsItems && (
            <div className={styles.Article_BreadCrumbs}>
              <BreadCrumbs items={breadCrumbsItems} />
            </div>
          )}
          <div className={styles.Article_PaneWrapper}>
            <div className={styles.Article_Wrapper}>
              <div className={styles.Article_Date}>
                <span>{article.updatedAt} 更新</span>
              </div>
              <div className={styles.Article_Category}>
                {article.category?.name && (
                  <ChipRowList>
                    <Chip
                      text={article.category?.name}
                      link={article.category.link}
                    />
                  </ChipRowList>
                )}
              </div>
              <h1 className={styles.Article_Title}>{article.title}</h1>
              <div className={styles.Article_Summary}>
                {data.summaryIcon?.publicURL && (
                  <img
                    src={data.summaryIcon.publicURL}
                    alt=""
                    className="inline pr-2"
                  />
                )}
                <span className="font-bold text-s">サマリー</span>
                <p className="pt-2">{article.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.Article_Wrapper}>
          {article.body && <ArticleBody body={article.body} />}
          {/* <div className={styles.Article_Others}>
                {article.category?.articles && (
                  <>
                    <h4>{`${article.category.name}の解説記事`}</h4>
                    <div className="items-stretch flex">
                      <RowLinkCardList
                        articles={article.category.articles.map((v) => ({
                          name: v.title,
                          link: v.link,
                        }))}
                      />
                    </div>
                  </>
                )}
              </div> */}
        </div>

        <div className={styles.Article_ShareButtonWrapper}>
          <li className={styles.Article_ShareButton}>
            <div className={styles.Article_ShareButton__Twitter}>
              <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="twitter-share-button"
              >
                Tweet
              </a>
            </div>
          </li>
          <li className={styles.Article_ShareButton}>
            <a
              href="https://b.hatena.ne.jp/entry/"
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="basic-label-counter"
              data-hatena-bookmark-lang="ja"
              title="このエントリーをはてなブックマークに追加"
              data-show-count="false"
            >
              <img
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
                width="20"
                height="20"
              />
            </a>
          </li>
        </div>

        <VoicesLinkFooter />

        {breadCrumbsItems && (
          <div className={styles.Article_BreadCrumbsBottom}>
            <BreadCrumbs items={breadCrumbsItems} />
          </div>
        )}
      </Assessment2PaneLayout>
    </>
  );
};

export const Article: React.VFC<ContainerProps> = (props) => {
  const data: GatsbyArticlePageComponentQuery = useStaticQuery(graphql`
    query ArticlePageComponent {
      summaryIcon: file(relativePath: { eq: "summary.svg" }) {
        publicURL
      }
    }
  `);
  const { article } = props;
  const [layout, setLayout] = useState("sp");
  const [breadCrumbsItems, setBreadCrumbsItems] = useState<BreadCrumbsItem[]>(
    [],
  );

  const updateBreadCrumbs = useCallback(
    (item: BreadCrumbsItem | undefined) => {
      if (item == null) return;
      setBreadCrumbsItems((prev) => [
        ...prev,
        { text: item.text, link: item.link },
      ]);
    },
    [setBreadCrumbsItems],
  );

  useEffect(() => {
    if (window.matchMedia("(min-width: 960px)").matches) {
      setLayout("pc");
    } else {
      setLayout("sp");
    }
  }, []);

  useEffect(() => {
    updateBreadCrumbs({ text: "トップ", link: "/" });
    updateBreadCrumbs({ text: "セキュリティ診断", link: "/assessment" });
    updateBreadCrumbs(
      article.category && {
        text: article.category.name,
        link: article.category.link,
      },
    );
    updateBreadCrumbs({ text: article.title });
    return () => setBreadCrumbsItems([]);
  }, [article, updateBreadCrumbs, setBreadCrumbsItems]);

  if (layout === "sp") {
    return (
      <SPComponent {...props} breadCrumbsItems={breadCrumbsItems} data={data} />
    );
  }

  return (
    <PCComponent {...props} breadCrumbsItems={breadCrumbsItems} data={data} />
  );
};
