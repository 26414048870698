import React from "react";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";

type Theme = "primary" | "default";

type Props = {
  text: string;
  link?: string;
  theme?: Theme;
};

export const Chip: React.FC<Props> = (props) => {
  const { text, link } = props;

  if (!link) {
    return <span className={styles.Article_ChipRow}>{text}</span>;
  }
  return (
    <Link to={link} className={styles.Article_ChipRow}>
      {text}
    </Link>
  );
};
